import './index.css';
import Question from './Question';

function App() {
  return (
    <div className='App'>
      <Question q="I sistemi operativi della Apple proteggono i dispositivi dalle vulnerabilità del firmware del processore di rete" ans="true"></Question> {/* 1 */}
      <Question q="V509 è lo standard per PKI e definisce i formati per i certificati digitali" ans="true"></Question> {/* 2 */}
      <Question q="Le chiavi pubbliche degli enti certificatori sono sempre disponibili sui Sistemi Operativi" ans="true"></Question> {/* 3 */}
      <Question q="Il progetto OpenSSL è un'implementazione open source del protocollo SSL/TLS e la libreria principale è scritta in C++" ans="false"></Question> {/* 4 */}
      <Question q="Il client genera il master secret" ans="false"></Question> {/* 5 */}
      <Question q="Il protocollo TKIP vanta una cifratura a 212bit" ans="false"></Question> {/* 6 */}
      <Question q="I sottoprotocolli fondamentali di TLS sono: Handshake(livello 4) e Record(livello 7)" ans="false"></Question> {/* 7 */}
      <Question q="Le diverse versioni del protocollo SSL/TLS non sono utilizzate per le chiamate VoIP" ans="false"></Question> {/* 8 */}
      <Question q="Le prime implementazioni di SSL erano a cifratura abbastanza debole da poter essere forzata dalle autorità giudiziarie" ans="true"></Question> {/* 9 */}
      <Question q="Il protocollo 802.11ac con il protocollo di sicurezza WPA2-AES garantisce una velocità massima teorica di 3,46 Gbps" ans="false"></Question> {/* 10 */}
      <Question q="Il protocollo OpenSSL deteneva una falla che ha permesso un attacco di tipo HeartBleed" ans="true"></Question> {/* 11 */}
      <Question q="Spesso il TLS si accontenta della sola autenticazione del server (presso il client)" ans="false"></Question> {/* 12 */}
      <Question q="La firma digitale dei certificati del server è validata e riconosciuta da una certificate authority conosciuta utilizzando una cifratura a chiave privata" ans="false"></Question> {/* 13 */}
      <Question q="Il protocollo SSL/TLS nel modello ISO/OSI è inizializzato al livello 6 e reso funzionale al livello 5" ans="false"></Question> {/* 14 */}
      <Question q="Il protocollo SSL/TLS permette ad applicazioni client/server di comunicare per mezzo di una rete in modo da prevenire l'eavesdropping e il tampering" ans="true" ></Question> {/* 15 */}
      <Question q="In riferimento al TLS Record il MAC non è un codice di autenticazione del messaggio generato da una funzione di hash" ans="false"></Question> {/* 16 */}
      <Question q="Il server crea il cosidetto pre-master session" ans="false"></Question> {/* 17 */}
      <Question q="Il TLS può aprire diverse sezioni rinegoziando da capo i parametri di sicurezza" ans="false"></Question> {/* 18 */}
      <Question q="Nell'autenticazione TLS unilaterale: è solo il client ad autenticarsi presso il server (il server conosce l'identità del client ma rimane anonimo e non autenticato sul client)" ans="false"></Question> {/* 19 */}
      <Question q="Il TLS Record dopo aver aggiunto l'header al pacchetto cifrato passa il tutto al TCP" ans="true"></Question> {/* 20 */}
    </div>
  );
}

export default App;

import React from "react";

function Question(props){
    return (
        <div className="Answer"> 
            <p className={props.ans}> [{props.ans==="false" ? "F" : "V"}] {props.q}</p>
        </div>
    );
}

export default Question;